import './home.styles.scss';

const Home = () => {
  return (
    <div className='home-container'>
	<h2>This is an assignment for No App </h2>
	<p>It includes authentication, one to one chat and Room Chat using socket io</p>
    </div>
  )
}

export default Home